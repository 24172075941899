<template>
  <div class="text-right">
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark" class="mx-1"></b-spinner>
          <b-spinner type="grow" variant="dark" class="mx-1"></b-spinner>
          <b-spinner small type="grow" variant="dark" class="mx-1"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-card class="border-0" title="تیکت ها">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="title">عنوان</label>
                  <b-form-input id="title" v-model="searchParams.title"></b-form-input>
                </div>
              </div>
              <div class="col-md-3">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="closed">بسته شده</b-form-select-option>
                    <b-form-select-option value="pending">در حال انتظار</b-form-select-option>
                    <b-form-select-option value="answered">پاسخ داده شده</b-form-select-option>
                    <b-form-select-option value="submitted">ثبت شده</b-form-select-option>
                    <b-form-select-option value="new">خوانده نشده</b-form-select-option>
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
              <b-form-group class="col-md-3" label="از تاریخ" label-for="start_date">
                <VuePersianDatetimePicker type="datetime" v-model="searchParams.start_date" format="YYYY-MM-DD HH:MM" display-format="jYYYY-jMM-jDD HH:MM" />
              </b-form-group>
              <b-form-group class="col-md-3" label="تا تاریخ" label-for="end_date">
                <VuePersianDatetimePicker type="datetime" v-model="searchParams.end_date" format="YYYY-MM-DD HH:MM" display-format="jYYYY-jMM-jDD HH:MM" />
              </b-form-group>
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
      <div class="mt-4" v-if="items.data">
        <b-table responsive hover class="ticket-table" :items="items.data.data" :fields="fields">
          <template v-slot:cell(id)="data">
            <router-link :to="{ name: 'admin.ticket.show', params: { id: data.item.id } }">
              {{ data.item.id }}
            </router-link>
          </template>
          <template v-slot:cell(title)="data">
            <router-link :to="{ name: 'admin.ticket.show', params: { id: data.item.id } }">
              {{ data.item.title }}
            </router-link>
          </template>
          <template v-slot:cell(created_at)="data">
            <router-link :to="{ name: 'admin.ticket.show', params: { id: data.item.id } }">
              {{ data.item.created_at | persianDate }}
            </router-link>
          </template>
          <template v-slot:cell(updated_at)="data">
            <router-link :to="{ name: 'admin.ticket.show', params: { id: data.item.id } }">
              {{ data.item.updated_at | persianDate }}
            </router-link>
          </template>
          <template v-slot:cell(status)="data">
            <router-link :to="{ name: 'admin.ticket.show', params: { id: data.item.id } }">
              <p v-if="data.item.status == 'closed'" class="badge badge-danger p-2">
                بسته شده
              </p>
              <p v-else-if="data.item.status == 'pending'" class="badge badge-warning p-2 text-light">
                در حال انتظار
              </p>
              <p v-else-if="data.item.status == 'answered'" class="badge badge-success p-2">
                پاسخ داده شده
              </p>
              <p v-else-if="data.item.status == 'submitted'" class="badge badge-primary p-2">
                ثبت شده
              </p>
              <p v-else-if="data.item.status == 'new'" class="badge badge-info p-2">
                خوانده نشده
              </p>
            </router-link>
          </template>
          <template v-slot:cell(user)="data">
            <b-button variant="info" @click="showItem(data.item)">
              <i class="fa fa-user" aria-hidden="true"></i>
            </b-button>
          </template>
          <template v-slot:cell(delete)="data">
            <b-button variant="danger" @click="deleteItem(data.item.id)">
              <i class="fa fa-trash" aria-hidden="true"></i></b-button>
          </template>
        </b-table>
        <pagination v-if="!search" :limit="2" :data="items.data" @pagination-change-page="loadItems"></pagination>
        <pagination v-else :limit="2" :data="items.data" @pagination-change-page="searchItems"></pagination>
        <div class="alert alert-danger" v-if="items.data.data == ''">
          <span>موردی یافت نشد ! </span>
        </div>
      </div>
      <b-modal id="show-modal" title="مشاهده فروشنده" hide-footer size="lg">
        <div v-if="edit.user" class="row">
          <div class="col-md-6 py-1">
            <span class="fontWeightBold">نام و نام خانوادگی : </span>
            <span>{{edit.user.name + ' ' + edit.user.last_name}}</span>
          </div>
          <div class="col-md-6 py-1">
            <span class="fontWeightBold">نام کاربری : </span>
            <span>{{edit.user.username}}</span>
          </div>
          <div class="col-md-6 py-1">
            <span class="fontWeightBold">شماره تماس : </span>
            <span>{{edit.user.phone_number}}</span>
          </div>
          <div class="col-md-6 py-1">
            <span class="fontWeightBold">پست الکترونیکی : </span>
            <span>{{edit.user.email}}</span>
          </div>
          <div class="col-md-6 py-1">
            <span class="fontWeightBold">مالکیت : </span>
            <span v-if="edit.user.ownership == 'legal'">حقوقی</span>
            <span v-else-if="edit.user.ownership == 'character'">حقیقی</span>
          </div>
          <div class="col-md-6 py-1">
            <span class="fontWeightBold">نام شرکت : </span>
            <span>{{edit.user.company_name}}</span>
          </div>
          <div class="col-md-6 py-1">
            <span class="fontWeightBold">دسته‌بندی : </span>
            <span v-for="(item, index) in edit.user.categories" :key="index">{{item.name}}
              {{ index != edit.user.categories.length -1 ? ' - ' : '' }}</span>
          </div>
          <div class="col-md-6 py-1">
            <span class="fontWeightBold">تعداد تنوع های محصول : </span>
            <span v-if="edit.user.product_varieties_count == 'zero_to_fifty'">0 تا 50</span>
            <span v-else-if="edit.user.product_varieties_count == 'fifty_to_hundred'">50 تا 100</span>
            <span v-else-if="edit.user.product_varieties_count == 'higher_than_hundred'">بالای 100</span>
          </div>
          <!-- <div class="col-md-6 py-1">
            <span class="fontWeightBold">شهر : </span>
            <span>{{edit.user.city ? edit.user.city.name : edit.city_id}}</span>
          </div> -->
          <div class="col-md-6 py-1">
            <span class="fontWeightBold">وضعیت : </span>
            <span class="badge badge-warning" v-if="edit.user.status == 'pending'">درحال انتظار</span>
            <span class="badge badge-success" v-else-if="edit.user.status == 'approved'">تایید شده</span>
            <span class="badge badge-danger" v-else-if="edit.user.status == 'disapproved'">رد شده</span>
          </div>
          <div  style="max-width: 200px; max-height: 200px;">
            <a :href="edit.user.license_image.url" target="_blank">
              <img :src="edit.user.license_image.url" class="w-100 h-100 rounded" >
            </a>
          </div>

        </div>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
  import VuePersianDatetimePicker from "vue-persian-datetime-picker";
  import mixins from "../mixins/mixins";
  export default {
    components: {
      VuePersianDatetimePicker
    },
    mixins: [mixins],
    data() {
      return {
        url: "/api/admin/tickets",
        tickets: [],
        fields: [{
            key: "id",
            label: "شناسه",
          },
          {
            key: "title",
            label: "عنوان",
          },
          {
            key: "created_at",
            label: "تاریخ ثبت",
          },
          {
            key: "updated_at",
            label: "آخرین به روزرسانی",
          },
          {
            key: "status",
            label: "وضعیت",
          },
          {
            key: "user",
            label: "کاربر",
          },
          {
            key: "delete",
            label: "حذف",
          },
        ],
        loading: true,
        disabled: false,
        title: "تیکت ها",
        text: "",
      };
    },
    mounted() {
      this.loadItems();
      this.searchParams = {
        title: '',
        status: '',
        start_date: '',
        end_date: ''
      }
    },
    methods: {
      searchItems(page = 1) {
        this.disabled = true
        this.search = true

        this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page, {
            params: {
              search1: this.searchParams.title,
              searchBy1: 'title',
              search2: this.searchParams.status,
              searchBy2: 'status',
              start_date: this.searchParams.start_date ? window.moment(this.searchParams.start_date, "YYYY.MM.DD").unix() : "",
              end_date: this.searchParams.end_date ? window.moment(this.searchParams.end_date, "YYYY.MM.DD").unix() : "",
            }
          })
          .then(response => {
            this.search = true
            this.items = response.data
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
    },
  };
</script>
<style scoped>
  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .box-shadow {
    box-shadow: -3px 0px 12px #eee;
  }
</style>
<style>
  .ticket-table a {
    color: #333;
  }

  .ticket-new .btn,
  .ticket-new .btn-light:focus {
    border-radius: 50px !important;
    border: 2px solid #555555 !important;
    /* box-shadow: unset !important; */
    background-color: #fff !important;
  }

  .ticket-new .btn-light:hover {
    border: 2px solid #0079fe !important;
    background-color: #0079fe !important;
    color: #fff !important;
  }

  #ticket-modal textarea {
    border-radius: 4px !important;
  }

  .ticket-table .btn {
    box-shadow: unset !important;
  }

  .ticket-table a:hover {
    color: #555 !important;
  }
</style>